<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>任务管理</el-breadcrumb-item>
                    <el-breadcrumb-item>任务列表</el-breadcrumb-item>
                    <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-form label-width="150px" ref="form" :model="form">
            <div class="admin_main_block admin_m15">
                <div class="admin_main_block_top">
                    <div class="admin_main_block_right">
                        <el-row>
                            <el-col :span="8">
                            </el-col>
                            <el-col :span="8">
                                <el-button type="primary" @click="success('form')">确认</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div class="admin_form_main">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="设置" name="first">
                            <el-form-item label="份数" prop="totalPut"
                                          :rules="[{ required: true, message: '请填写份数', trigger: 'blur' },]">
                                <el-input-number type="text" placeholder="" :min="0" :max="100000" :step="10"
                                                 :disabled="disabled"
                                                 v-model="form.totalPut"></el-input-number>
                            </el-form-item>
                            <el-form-item label="日期" prop="endDate"
                                          :rules="[{ required: true, message: '请填选择日期', trigger: 'blur' },]">
                                <el-date-picker
                                        v-model="date"
                                        @change="changeDate" @input="immediUpdate"
                                        type="daterange"
                                        range-separator="~"
                                        value-format="yyyy-MM-dd"
                                        format="yyyy-MM-dd"
                                        start-placeholder="开始日期"
                                        end-placeholder="截止日期"
                                        :clearable="true"
                                        unlink-panels
                                >
                                    <i class="el-icon-date"></i>
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="时间" prop="endHour"
                                          :rules="[{ required: true, message: '请填选择时间', trigger: 'blur' },]">
                                <el-select v-model="form.startHour" placeholder="开始时间" @change="setStartHour">
                                    <el-option v-for="(rs,index) in 24" :label="(rs < 10 ? '0'+rs:rs)+':00'" :value="rs"
                                               :key="index"></el-option>
                                </el-select>
                                <el-select v-model="form.endHour" placeholder="结束时间">
                                    <el-option v-for="(rs,index) in endHour" :label="rs.label" :value="rs.value"
                                               :disabled="rs.disabled"
                                               :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="标题" prop="name"
                                          :rules="[{ required: true, message: '请填写标题', trigger: 'blur' },]">
                                <el-input type="text" placeholder="请填写标题" :disabled="disabled"
                                          v-model="form.name"></el-input>
                            </el-form-item>
                            <el-form-item label="金额" prop="money"
                                          :rules="[{ required: true, message: '请填写金额', trigger: 'blur' },]">
                                <el-input-number placeholder="0.00" :disabled="disabled" :precision="2" :step="0.1"
                                                 :max="10000"
                                                 v-model="form.money"></el-input-number>
                                元
                            </el-form-item>
                            <el-form-item label="图标">
                                <upload-img
                                        @getUploadFile="getUploadImage"
                                        title=""
                                        field="pic"
                                        :param="upload.office"
                                        :urlPath="form.pic ? form.pic : ''"
                                ></upload-img>
                                <div style="font-size: 12px;color: #606266;">
                                    分辨率：222*222、4:4的比例上传<br>
                                    图片格式支持：PNG、JPG、GIF格式
                                </div>
                            </el-form-item>
                            <el-form-item label="标签">
                                <el-tag
                                        :key="tag"
                                        v-for="tag in dynamicTags"
                                        closable
                                        size="medium"
                                        :disable-transitions="false"
                                        @close="handleClose(tag)">
                                    {{tag}}
                                </el-tag>
                                <el-input
                                        class="input-new-tag"
                                        v-if="inputVisible"
                                        v-model="inputValue"
                                        ref="saveTagInput"
                                        size="small"
                                        placeholder="请输入标签"
                                        @keyup.enter.native="handleInputConfirm"
                                        @blur="handleInputConfirm"
                                >
                                </el-input>
                                <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增
                                </el-button>
                            </el-form-item>
                            <el-form-item label="任务时间" prop="taskTime"
                                          :rules="[{ required: true, message: '请填选择任务时间', trigger: 'blur' },]">
                                <el-select v-model="form.taskTime" placeholder="任务时间">
                                    <el-option v-for="(rs,index) in taskTime" :label="rs.name" :value="rs.id"
                                               :key="index"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="须知" prop="instructions"
                                          :rules="[{ required: true, message: '请填写须知', trigger: 'blur' },]">
                                <el-input placeholder="" type="textarea"
                                          :rows="10" :disabled="disabled" v-model="form.instructions"></el-input>
                            </el-form-item>
                        </el-tab-pane>
                        <el-tab-pane label="步骤" name="second">
                            <el-form-item label="">
                                <el-button type="success" icon="el-icon-plus" @click="add(10)">图片步骤</el-button>
                                <el-button type="info" icon="el-icon-plus" @click="add(20)">文字步骤</el-button>
                                <el-button type="warning" icon="el-icon-plus" @click="add(30)">复制文案步骤</el-button>
                                <el-button type="danger" icon="el-icon-plus" @click="add(40)">下载链接</el-button>
                            </el-form-item>
                            <el-form-item :label="(key+1)+''" class="step" v-for="(item ,key) in form.cashTaskFlows"
                                          :key="key">
                                <el-row>
                                    <el-col :span="span8">是否需要上传验证：</el-col>
                                    <el-col :span="span8">
                                        <el-radio v-model="item.isCheck" :label="0">否</el-radio>
                                        <el-radio v-model="item.isCheck" :label="1">是</el-radio>
                                    </el-col>
                                    <el-col :span="span8" class="last-col">
                                        <i class="el-icon-circle-close" @click="del(key)"></i>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="span8">
                                        <upload-img
                                                v-if="item.type === 10"
                                                @getUploadFile="getUploadStepImage"
                                                title=""
                                                :field="key+''"
                                                :param="{fileType:'task/AuditReview'}"
                                                :urlPath="item.pic ? item.pic : ''"
                                        ></upload-img>
                                        <div v-if="item.type === 10" style="font-size: 12px;color: #606266;">
                                            请图片步骤内容<br>
                                            图片格式支持：PNG、JPG、GIF格式
                                        </div>
                                        <el-input v-else-if="item.type === 30" type="text" placeholder="请输入复制文案内容"
                                                  :disabled="disabled"
                                                  v-model="item.content"></el-input>
                                        <el-input v-else-if="item.type === 40" type="text" placeholder="请输入下载内容"
                                                  :disabled="disabled"
                                                  v-model="item.content"></el-input>
                                    </el-col>
                                    <el-col :span="span8">
                                        <el-input type="textarea" placeholder="请输入描述内容" :disabled="disabled"
                                                  v-model="item.descr" :rows="5"></el-input>
                                    </el-col>
                                    <el-col :span="span8">
                                    </el-col>
                                </el-row>
                            </el-form-item>
                        </el-tab-pane>
                        <el-tab-pane label="资料" name="third" class="zl">
                            <el-form-item label="">
                                <el-row>
                                    <el-col :span="8">
                                        <el-input type="text" placeholder="手机号" :disabled="true"
                                                  v-model="zl.title"></el-input>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-input type="text" placeholder="注册所用手机号" :disabled="true"
                                                  v-model="zl.value"></el-input>
                                    </el-col>
                                    <el-col :span="2" style="text-align: center">
                                        <el-switch
                                                v-model="valueMobile"
                                                active-color="#67C23A"
                                                inactive-color="#dcdfe6">
                                        </el-switch>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                            <el-form-item label="" v-for="(item ,key) in form.zl" :key="key">
                                <el-row>
                                    <el-col :span="8">
                                        <el-input type="text" placeholder=""
                                                  v-model="item.title"></el-input>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-input type="text" placeholder=""
                                                  v-model="item.value"></el-input>
                                    </el-col>
                                    <el-col :span="2" style="text-align: center">
                                        <el-button type="primary" plain icon="el-icon-delete"
                                                   @click="delZl(key)"></el-button>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                            <el-form-item label="">
                                <el-button type="success" @click="addZl">新增项</el-button>
                            </el-form-item>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import uploadImg from "@/components/admin/upload";

    export default {
        components: {
            uploadImg,
        },
        data() {
            return {
                span8: 8,
                title: "新增",
                activeName: "first",
                /*定义前一个活动Tab,初始化默认为 ActiveTab */
                oldTab: "add",
                /*定义Tab是否加载-第一个默认加载 */
                firstIs: true,
                secondIs: false,
                forthIs: false,
                fifthIs: false,
                sixthIs: false,
                thirdIs: false,
                disabled: false,
                date: '',
                time: '',
                valueMobile: false,
                zl:
                    {title: '手机号', value: '注册所用手机号'}
                ,
                form: {
                    zl: [],

                    totalPut: '',  // 总投放数量
                    startDate: '', // 开始日期【yyyy-MM-dd】
                    endDate: '',  // 结束日期【yyyy-MM-dd】
                    startHour: '',  // 开始小时，int整数。从0开始
                    endHour: '',   // 结束小时，int整数。从0开始，最大24
                    name: '',   // 任务标题
                    money: '',  // 任务单价
                    pic: '',   // 任务图标
                    label: '',  // 任务标签，多个用英文逗号隔开
                    taskTime: '',  // 做任务时间，int类型，以分钟为单位
                    instructions: '', // 任务须知
                    cashTaskFlows: [], // 任务步骤对象 集合
                },
                dynamicTags: [],
                inputVisible: true,
                inputValue: '',

                upload: {
                    office: {fileType: "task/head"},
                },
                taskTime: [
                    {id: '30', 'name': '30分钟'},
                    {id: '60', 'name': '1小时'},
                    {id: '120', 'name': '2小时'},
                    {id: '180', 'name': '3小时'},
                    {id: '360', 'name': '6小时'},
                    {id: '720', 'name': '12小时'},
                    {id: '1440', 'name': '24小时'},
                ],
                endHour:[],
            }
        },
        created() {
            this.form.id = this.$route.query.id;
            if (this.form && this.form.id) {
                this.title = "编辑";
                this.showDetail();
            }
        },
        methods: {
            handleClick(tab) {
                this[tab.name + "Is"] = true;
                if (tab.name !== this.oldTab) {
                    this[this.oldTab + "Is"] = false;
                    this.oldTab = tab.name;
                }
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let url = this.$api.taskCashSaveTask;
                        console.log("submitForm", this.form)
                        if (this.form.id) {
                            url = this.$api.taskCashEditTask;
                        }
                        this.$post(
                            url,
                            this.form
                        ).then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    type: "success",
                                    message: "成功",
                                });
                                this.$router.push({name: 'taskCheckList'});
                            } else {
                                this.$message({
                                    type: "info",
                                    message: res.msg,
                                });
                            }
                        });
                    } else {
                        this.$message({
                            type: "warning",
                            message: "请填写完整信息！",
                        });
                        return false;
                    }
                });
            },
            success(formName) {
                console.log(JSON.stringify(this.form));
                let step = '';
                this.form.cashTaskFlows.map((item) => {
                    if (item.type === 10 && item.pic.length === 0) {
                        step = "请填写完整图文步骤示例图片！";
                    }
                    if (item.type === 10 && item.descr.length === 0) {
                        step = "请填写完整图文步骤示例内容信息！";
                    }
                    if (item.type === 20 && item.descr.length === 0) {
                        step = "请填写完整文字步骤信息！";
                    }
                    if (item.type === 30 && item.content.length === 0) {
                        step = "请填写完整复制步骤示例内容！";
                    }
                    if (item.type === 30 && item.descr.length === 0) {
                        step = "请填写完整复制步骤示例内容描述！";
                    }
                    if (item.type === 40 && item.content.length === 0) {
                        step = "请填写完整下载步骤示例内容！";
                    }
                    if (item.type === 40 && item.descr.length === 0) {
                        step = "请填写完整下载步骤示例内容描述！";
                    }
                });
                if (step) {
                    this.activeName = 'second';
                    this.$message({type: "warning", message: step});
                    return false;
                }

                //校验资料
                /*const info = this.form.zl.filter(o => o.title === '');
                if (info.length > 0) {
                    this.activeName = 'third';
                    this.$message({type: "warning", message: "请填写完整资料信息！"});
                    return false;
                }*/

                this.$confirm("是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        //追加默认手机号
                        if (this.valueMobile) {
                            this.form.zl.unshift(this.zl);
                        }

                        console.log(this.form.zl, 11111111);
                        this.submitForm(formName);
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消",
                        });
                    });
            },

            changeDate(obj) {
                if (obj) {
                    this.form.startDate = obj[0];
                    this.form.endDate = obj[1];
                }
            },
            immediUpdate(e) {
                this.$nextTick(() => {
                    console.log(e)
                    if(e==null){
                        this.form.startDt = '';
                        this.form.endDt = '';
                    }
                });
            },
            handleClose(tag) {
                this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
            },

            showInput() {
                this.inputVisible = true;
                this.$nextTick(() => {
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },

            handleInputConfirm() {
                if (this.dynamicTags.length > 5) {
                    this.$message({
                        type: "warning",
                        message: "不能超出6个标签",
                    });
                    this.inputVisible = false;
                    this.inputValue = '';
                    return;
                }
                let inputValue = this.inputValue;
                if (inputValue.length > 5) {
                    this.$message({
                        type: "warning",
                        message: "不能超出6个字",
                    });
                    this.inputVisible = false;
                    this.inputValue = '';
                    return;
                }
                if (inputValue) {
                    this.dynamicTags.push(inputValue);
                    this.form.label = this.dynamicTags.join(',');
                    console.log(this.form.label)
                }
                this.inputVisible = false;
                this.inputValue = '';
            },
            getUploadImage(val) {
                this.form[val.field] = val.url;
            },
            getUploadStepImage(val) {
                this.form.cashTaskFlows[val.field].pic = val.url;
            },
            add(type) {
                let data = {
                    isCheck: 0,
                    type: type,//1:图片步骤;2:文字步骤;3:复制文案步骤;4:下载链接;
                    descr: '',
                    content: '',
                    pic: '',
                };
                this.form.cashTaskFlows.push(data);
            },
            del(index) {
                this.form.cashTaskFlows.splice(index, 1);
            },
            addZl() {
                this.form.zl.push({title: '', value: ''});
            },
            delZl(index) {
                console.log(index)
                if (this.valueMobile) {
                    index++;
                }
                this.form.zl.splice(index, 1);
            },
            showDetail() {
                this.$get(this.$api.taskCashtaskDetail + this.form.id).then((res) => {
                    if (res.code === 1) {
                        this.form = res.data;
                        this.setStartHour(this.form.startHour);
                        this.date = [this.form.startDate , this.form.endDate];
                        this.dynamicTags = this.form.label.split(',');
                    }
                });
            },
            setStartHour(obj){
                this.endHour = [];
                for (let i = 1;i<=24;i++){
                    this.endHour.push({
                        value: i,
                        label: (i < 10 ? '0'+i:i)+":00",
                        disabled: (obj >= i)
                    })
                }
                console.log(obj,this.endHour);
            }
        },
    }
</script>

<style lang="scss" scoped>
    .el-breadcrumb {
        line-height: 32px;
    }

    .admin_main_block_right {
        width: 280px;
    }

    /deep/
    .image-slot {
        font-size: 30px;
    }

    .el-image {
        width: 250px;
        height: 200px;
    }

    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }

    .admin_form_main .el-form-item {
        width: 600px;
    }

    /deep/
    .step {
        width: 99% !important;
        border: #d9d9d9 1px solid;
        padding-top: 10px;

        .el-form-item__label {
            text-align: center !important;
            font-size: 28px;
        }

        .el-col-8 {
            padding: 10px;
        }
    }

    .last-col {
        position: relative;

        .el-icon-circle-close {
            position: absolute;
            right: 7px;
            top: -5px;
            cursor: pointer;
            font-size: 26px;
            color: #909399;

            &:hover {
                color: #F56C6C;
            }
        }

    }

    /deep/
    .el-upload-dragger {
        width: 130px;
    }

    /deep/
    .el-upload-dragger .el-upload__text {
        padding: 0;
        min-width: auto;
        margin: auto 36px;
        margin-top: 90px;
    }

    .zl {
        .el-col {
            padding: 0 5px;
        }
    }

    .time {
        width: 175px;
    }

    .input-new-tag {
        width: 115px;
    }
</style>
